* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: aqua;
  height: 100vh;
  width: 100vw;
}

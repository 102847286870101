input {
  border: 1px solid gray;
  height: 30px;
}
input:focus {
  outline: none;
}
.meme-content {
  display: flex;
  flex-direction: column;
}
.meme-content {
  margin-top: 1rem;
}
.meme-creator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  height: 50px;
  color: white;
  background-image: linear-gradient(
    to right,
    rgba(139, 36, 174, 1),
    rgba(138, 62, 205, 1)
  );
  border-radius: 10px;
  cursor: pointer;
}
.input {
  display: flex;
  justify-content: space-between;
}
.input-1,
.input-2 {
  height: 40px;
  width: 48%;
  font-size: large;
  text-indent: 5px;
  border-radius: 10px;
}
.image-meme {
  height: 350px;
  margin-top: 1rem;
}
.meme-created-image {
  height: 220px;
  width: 100%;
  border-radius: 10px;
}

.meme-input-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: green;
  font-style: italic;
  font-size: large;
}
.meme-generated-content-one {
  position: absolute;
  top: -330px;
  z-index: 10;
}
.meme-generated-content-two {
  position: absolute;
  top: -210px;
  z-index: 11;
}

.wrapper {
  box-sizing: border-box;
  width: 400px;
  height: 480px;
  margin: auto;
  background-color: white;
}
.content {
  box-sizing: border-box;
  height: 500px;
  margin-left: 2rem;
  margin-right: 2rem;
}
